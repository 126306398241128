/* eslint-disable @angular-eslint/no-output-native */
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
} from '@angular/core';

import screenfull from 'screenfull';
import { input } from '@angular/core';
import { output } from '@angular/core';

export type FullscreenChangeEvent = {
  isFullscreen: boolean;
  event: Event;
};

@Directive({
  selector: '[toggleFullscreen]',
  standalone: true,
})
export class ToggleFullscreenDirective implements AfterViewInit, OnDestroy {
  /**
   * custom toggle target, if not set element, that will be whole html
   */
  toggleTarget = input<ElementRef<any> | HTMLElement | '' | undefined>(
    undefined,
    { alias: 'toggleFullscreen' },
  );

  /**
   * `bindChange` is true,
   */
  change = output<FullscreenChangeEvent>();

  screenfull = screenfull;

  private elm!: HTMLElement;

  @HostListener('click')
  onClick() {
    if (this.screenfull.isEnabled) {
      if (this.elm) {
        this.screenfull.toggle(this.elm);
        return;
      }

      this.screenfull.toggle();
    }
  }

  ngAfterViewInit() {
    this.checkInputTarget();

    if (this.screenfull.isEnabled) {
      this.screenfull.on('change', this.fullscreenFn);
    }
  }

  ngOnDestroy() {
    if (this.screenfull.isEnabled) {
      this.screenfull.off('change', this.fullscreenFn);
    }
  }

  private fullscreenFn = (e: Event) => {
    if (this.screenfull.isEnabled) {
      this.change.emit({
        isFullscreen: this.screenfull.isFullscreen,
        event: e,
      });
    }
  };

  private checkInputTarget() {
    const target = this.toggleTarget();
    if (target) {
      if (target! instanceof ElementRef) {
        this.elm = target.nativeElement;
      } else {
        this.elm = target;
      }
    }
  }
}
