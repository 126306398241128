import { InjectionToken, TemplateRef } from '@angular/core';

export const GALLERY_TOKEN = new InjectionToken<NgxHmGalleryOption>('GALLERY');

export enum GALLERY_TYPE {
  YOUTUBE = 'youtube',
  IMG = 'img',
  VIDEO = 'video',
}

export interface NgxHmGalleryItem {
  type: GALLERY_TYPE;
  isDownload?: boolean;
  [key: string]: any;
}

export interface NgxHmGalleryOption {
  data: NgxHmGalleryItem[];
  itemTemplate: TemplateRef<any>;
  galleryTemplate: TemplateRef<any>;
  isDownload?: boolean;
  autoplay?: boolean;
  disableFullscreen?: boolean;
  currentIndex?: number;
}
