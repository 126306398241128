/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  OnInit,
  signal,
  TemplateRef,
  viewChild,
  viewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import {
  NgxHmCarouselComponent,
  NgxHmCarouselItemDirective,
} from 'ngx-hm-carousel';
import { NGX_RX_MODAL_TOKEN, NgxRxModalRef } from 'ngx-rx-modal';
import {
  FullscreenChangeEvent,
  ToggleFullscreenDirective,
} from 'ngx-screenfull';
import { BehaviorSubject, fromEvent, Subject, takeUntil, tap } from 'rxjs';

import { NgxHmGalleryItem, NgxHmGalleryOption } from './ngx-hm-gallery.model';
import { bindHammerZoom } from './utils/bind-hammer-zoom';

@Component({
  selector: 'ngx-hm-gallery',
  templateUrl: './ngx-hm-gallery.component.html',
  styleUrls: ['./ngx-hm-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgxHmCarouselComponent,
    FormsModule,
    NgxHmCarouselItemDirective,
    ToggleFullscreenDirective,
    NgTemplateOutlet,
    AsyncPipe,
  ],
})
export class NgxHmGalleryComponent implements NgxRxModalRef, OnInit {
  private _elm = inject(ElementRef);
  public _injectData = inject<NgxHmGalleryOption>(NGX_RX_MODAL_TOKEN);
  private changeDetectorRef = inject(ChangeDetectorRef);
  @HostBinding('class') classList = 'text-base';

  complete = new Subject<any>();

  gallery = viewChild('gallery', {
    read: NgxHmCarouselComponent,
  });

  containerRef = viewChild.required('container', { read: ElementRef });
  imgDownload = viewChild.required('imgDownload', { read: ElementRef });
  toggleFullscreen = viewChild<ToggleFullscreenDirective>('[toggleFullscreen]');
  galleryItems = viewChildren('galleryItem', { read: ElementRef });

  selectorOpen$ = new BehaviorSubject(true);

  data: NgxHmGalleryItem[] = this._injectData.data;
  isDownLoad = this._injectData.isDownload;
  isAutoPlay = this._injectData.autoplay;

  ItemTemplate: TemplateRef<any> = this._injectData.itemTemplate;
  GalleryTemplate: TemplateRef<any> = this._injectData.galleryTemplate;

  currentIndex = this._injectData.currentIndex || 0;
  public isFullScreen = false;

  recognizers: Recognizer[] = [
    new Hammer.Pinch(),
    new Hammer.Tap({ event: 'doubletap', taps: 2 }),
  ];
  action?: ReturnType<typeof bindHammerZoom>;

  get hammer() {
    return this.gallery()?.hammer;
  }

  galleryElm = this._elm.nativeElement;
  hasData = false;
  isCanFull = false;
  draggable = signal(false);
  scaling = false;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    evt: KeyboardEvent,
  ) {
    this.closeView();
  }

  @HostListener('document:keydown.arrowleft', ['$event']) onKeyleft(
    evt: KeyboardEvent,
  ) {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  @HostListener('document:keydown.arrowright', ['$event']) onKeyRight(
    evt: KeyboardEvent,
  ) {
    if (this.data && this.currentIndex < this.data.length - 1) {
      this.currentIndex++;
    }
  }

  async ngOnInit() {
    if (this.data && this.data.length > 0) {
      this.hasData = true;

      this.isCanFull = true;
    }
  }

  constructor() {
    // ref: https://bl.ocks.org/redgeoff/6be0295e6ebf18649966d48768398252
    const effectRef = effect(
      () => {
        if (!this.hammer) return;

        this.action = bindHammerZoom({
          hammer: this.hammer,
          min: 1,
          max: 20,
          getContainer: () => this.containerRef().nativeElement,
          getTarget: () => {
            const curr = this.galleryItems()[this.currentIndex];

            if (curr) {
              const elm = curr.nativeElement as HTMLElement;
              const result = elm.querySelector('img');

              if (result) {
                return result;
              }
            }
            throw new Error('Not found that element');
          },
          onScale: (rate) => {
            this.selectorOpen$.next(rate === 1);
            this.scaling = rate !== 1;
            this.changeDetectorRef.detectChanges();
          },
          onDragChange: (state) => {
            this.draggable.set(state);
          },
        });

        fromEvent<WheelEvent>(this.containerRef().nativeElement, 'wheel')
          .pipe(
            tap((e) => {
              const action = this.action;

              action?.zoomStart({ x: e.clientX, y: e.clientY });
              action?.zooming(e.deltaY < 0 ? 1.03 : 0.97);

              e.preventDefault();
            }),
            takeUntil(this.complete),
          )
          .subscribe();

        // only bind once
        effectRef.destroy();
      },
      {
        manualCleanup: true,
      },
    );
  }

  fullscreenChange(event: FullscreenChangeEvent) {
    this.isFullScreen = event.isFullscreen;
  }

  closeView(isBack?: boolean) {
    const toggleFullscreen = this.toggleFullscreen();
    if (this.isFullScreen && toggleFullscreen) {
      if (toggleFullscreen.screenfull.isEnabled) {
        toggleFullscreen.screenfull.exit();
      }
    }
    this.complete.next(true);
  }

  // download(file: NgxHmGalleryItem) {
  //   if (this.isCanDownLoad(file)) {
  //     // this._render.setAttribute(this.imgDownload.nativeElement, 'href', `${file.url}`);
  //     // this.imgDownload.nativeElement.click();
  //   }
  // }

  toggleSelector() {
    this.selectorOpen$.next(!this.selectorOpen$.value);

    if (this.action?.scale !== 1) {
      this.action?.reset();
    }

    (window as any).selectorOpen$ = this.selectorOpen$;
    this.changeDetectorRef.detectChanges();
  }

  // private isCanDownLoad(file: NgxHmGalleryItem) {
  //   return file.type !== GALLERY_TYPE.YOUTUBE && !!file.isDownload;
  // }

  indexChanged(index: number) {
    console.log(index);
  }

  toggleZoom() {
    this.action?.toggle();
  }
}
